import en from './locales/EN.json'
import cn from './locales/CN.json'
import hk from './locales/HK.json'
export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'cn',
    messages: {
      cn,
      hk
    }
  }))
